import { JackIcons } from "assets/jackIcons/parent";
import { titleCase } from "change-case";
import { ButtonJack } from "components/ButtonsJack/parent";
import CustomTooltip from "components/Tooltip";
import { ModalCloseButton } from "modals/SimpleCenteredModal";
import { useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { isProduction } from "../../components/tools";
import { ToasterHook } from "../../contexts/ToasterContext";
import { CenteredModal } from "../../modals/centeredComponents";
import { getUrlEnv } from "tools/api/functions/interceptors/url";

const useLocalStorage = (name) => {
  const [value, setValue] = useState("");

  useEffect(() => setValue(localStorage.getItem(value)), []);

  const setsValue = (value) => {
    setValue(value);
    localStorage.setItem(name, value);
  };

  return { value, setValue: setsValue };
};

export const EnvModal = ({ isOpen, toggle }) => {
  if (isProduction) return null;

  const array = [
    "original",
    "production",
    "prod_unbanned",
    "internal_production",
    "internal_production_2",
    "internal_production_io",
    "api2",
    "prod3",
    "sandbox",
    "staging",
  ];

  const { successToaster } = ToasterHook();
  const { value, setValue } = useLocalStorage("env_type");
  const [idHover, setIdHover] = useState(false);

  return (
    <CenteredModal isOpen={isOpen} style={{ position: "relative" }}>
      <div className="text-center">
        <GothamMedium className="font20" style={{ marginBottom: 8 }}>
          Environment Menu
        </GothamMedium>
        <GothamRegular style={{ marginBottom: 32 }}>
          Please select which environment you want to test
        </GothamRegular>
      </div>
      <div
        style={{
          display: "grid",
          gap: "10px",
          gridTemplateColumns: "1fr 1fr",
          marginBottom: 32,
        }}
      >
        {array.map((string, index) => {
          const isActiveDecider = () => {
            if (!value) return !index;
            return value == string;
          };

          const getLink = getUrlEnv(string);

          const isActive = isActiveDecider();
          const activeHover = idHover == index;

          const color = isActive
            ? jackColors.neutral900
            : jackColors.neutral600;

          const isOriginal = value == "original";

          const handleClick = () => {
            if (isActive) return;
            setValue(isOriginal ? "" : string);
          };
          return (
            <CustomTooltip
              key={index}
              customId={`tooltip-${index}`}
              text={getLink}
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                backgroundColor: jackColors.neutral900,
              }}
            >
              <div
                onClick={handleClick}
                key={index}
                style={{
                  border: `1px solid ${color}`,
                  // marginBottom: 12,
                  padding: 12,
                  borderRadius: 8,
                  cursor: "pointer",
                }}
                className="d-flex justify-content-between align-items-center hover-400"
                onMouseEnter={() => setIdHover(index)}
                onMouseLeave={() => setIdHover(index)}
              >
                <GothamMedium style={{ color: color }}>
                  {titleCase(string)}
                </GothamMedium>
                {isActive ? (
                  <JackIcons
                    name={isActive ? `checkmark-circle-2` : ``}
                    fill={jackColors.neutral900}
                  />
                ) : (
                  <div />
                )}
              </div>
            </CustomTooltip>
          );
        })}
      </div>
      <div
        style={{
          position: "absolute",
          right: 12,
          top: 12,
        }}
      >
        <ModalCloseButton onClick={toggle} />
      </div>
      <ButtonJack
        className="w-100"
        onClick={() => {
          toggle();
          successToaster({
            msg: `ENV successfully changed to ${titleCase(
              value || "original"
            )}!`,
          });
        }}
      >
        Confirm
      </ButtonJack>
    </CenteredModal>
  );
};
