import { Modal } from "reactstrap";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { windowDimension } from "../../components/tools";
import { Layout } from "pageComponents/loginPageComponents/components";
import SwitchLocaleButton from "components/LocaleButton";
import { ButtonJack } from "components/ButtonsJack/parent";
import kybrejected from "../../public/images/kyb-rejected.png";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { SelectionJack } from "components/inputs/selection";
import { requiredAndOptional } from "./requiredAndOptional";
import { useForm } from "react-hook-form";
import {
  annualTurnover,
  companySizes,
  monthlyTurnover,
} from "./details/constants";
import { useMutation } from "tools/api";
import { ToasterHook } from "contexts/ToasterContext";
import { useModalHook } from "components/Modals";
import { useGetAuth } from "contexts/AuthContext";

export const CenteredModalJack = ({
  isOpen,
  toggle,
  children,
  title,
  woBorder,
  width = 480,
  woPadding,
  contentClassName = "",
  woClassName,
  woCloseButton = false,
}) => {
  const { isTabOrPhone } = windowDimension();
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={
        woClassName ? contentClassName : `modal-transfez ${contentClassName}`
      }
    >
      <div style={{ width, maxWidth: "94vw" }}>
        {!woCloseButton && (
          <div
            style={{
              padding: 20,
              paddingBottom: woPadding ? 0 : 16,
              borderBottom: woBorder ? "" : `1px solid rgba(230, 230, 230, .5)`,
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <GothamMedium
                className={isTabOrPhone ? "font16" : "font20"}
                style={{
                  color: jackColors.black34,
                  marginRight: 8,
                }}
              >
                {title}
              </GothamMedium>
              <JackIcons
                name="close-outline"
                fill={jackColors.black34}
                className="hover"
                style={{ width: 20, height: 20 }}
                styleDiv={{
                  padding: 9,
                  border: `1px solid ${jackColors.greyE6}`,
                  borderRadius: 4,
                }}
                onClick={toggle}
              />
            </div>
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

export const KYBRejectedPage = ({ onClick = () => {} }) => {
  const { t } = useTranslation("kyb/kyb");
  const { t: tCommon } = useTranslation("common/common");
  const { push } = useRouter();
  return (
    <Layout woIcon>
      <div
        className="w-100 align-items-end justify-content-end d-flex"
        // style={{ padding: 20, paddingRight: 32, gap: 16 }}
        style={{ position: "absolute", top: 20, right: 32 }}
      >
        <SwitchLocaleButton noAuth />
        <ButtonJack
          onClick={() => {
            push("/login");
          }}
          style={{ marginLeft: 12 }}
          type="outline"
          rightIcon={
            <JackIcons name="log-out-outline" fill={jackColors.black34} />
          }
        >
          {tCommon("Log out")}
        </ButtonJack>
      </div>
      <img src={kybrejected} width={240} height={240} />
      <GothamMedium className="font28" style={{ textAlign: "center" }}>
        {t("We couldn’t verify your business due to regulations")}
      </GothamMedium>
      <GothamRegular style={{ textAlign: "center", color: "#6c6c71" }}>
        {t(
          "Currently, we cannot verify your business and provide you our services. We truly value your interest in Jack and apologize for the inconvenience."
        )}
      </GothamRegular>

      <GothamRegular style={{ color: "#6c6c71" }}>
        {t("If you have any questions, feel free to contact us.")}
      </GothamRegular>
      <ButtonJack
        onClick={() =>
          window.open(
            "https://wa.me/6285282051660",
            "_blank",
            "noopener,noreferrer"
          )
        }
        className="w-100"
      >
        {t("Contact Us")}
      </ButtonJack>
    </Layout>
  );
};

export const KYBUpdateAnnualAndCompanySize = ({
  isOpen,
  close,
  woCloseButton,
  afterSuccess,
}) => {
  const centeredModalStyle = {
    alignItems: "center",
    padding: 0,
    width: 600,
  };
  const { required } = requiredAndOptional();
  const { t } = useTranslation("kyb/kyb");
  const { successSnackBar } = ToasterHook();
  const { refetchUser } = useGetAuth();

  const useFormObj = useForm();
  const { mutation: updateCompany, loading: loadingUpdateCompany } =
    useMutation({
      url: "/company_info",
      method: "put",
      afterSuccess: (res) => {
        refetchUser();
        afterSuccess
          ? afterSuccess()
          : successSnackBar({
              msg: t("Company info updated successfully"),
            });
        close();
      },
    });

  const { handleSubmit, watch } = useFormObj || {};

  const onSubmit = (data) => {
    const payload = {
      // annual_turnover: data?.annual_turnover?.value,
      // total_employees: data?.total_employees?.value,
      annual_turnover: data?.annual_turnover?.value,
      total_employees: data?.total_employees?.value,
    };
    updateCompany(payload);
  };

  const submitDisabled = !watch("total_employees") || !watch("annual_turnover");

  return (
    <CenteredModalJack
      isOpen={isOpen}
      // toggle={toggleProps}
      style={centeredModalStyle}
      woCloseButton={woCloseButton}
    >
      <div className="d-flex flex-column  pb-0 px-4 pt-4">
        <GothamMedium className="font20" style={{ color: jackColors.black34 }}>
          {t("Keep your company profile up to date")}{" "}
        </GothamMedium>
        <GothamRegular
          className="font14"
          style={{ color: jackColors.black34, marginBottom: 20 }}
        >
          {t(
            "Help us provide this data to optimize our services for your business."
          )}
        </GothamRegular>

        <SelectionJack
          label={required(t("Company Size"))}
          name="total_employees"
          placeholder={t("Select company size")}
          options={companySizes(t)}
          useFormObj={useFormObj}
          containerStyle={{ marginBottom: 20 }}
          woAsterisk
        />
        <SelectionJack
          label={required(t("Annual Turnover (Estimation)"))}
          name="annual_turnover"
          placeholder={t("Select annual turnover")}
          options={annualTurnover(t)}
          useFormObj={useFormObj}
          containerStyle={{ marginBottom: 20 }}
          woAsterisk
        />

        {/* spacer */}
      </div>
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: jackColors.greyE6,
        }}
      />
      <div className="d-flex flex-column p-4">
        <ButtonJack
          isLoading={loadingUpdateCompany}
          disabled={submitDisabled}
          className="w-100"
          onClick={handleSubmit(onSubmit)}
          style={{ width: 200 }}
        >
          {t("Submit")}
        </ButtonJack>
      </div>
    </CenteredModalJack>
  );
};

export const UpdateKYBSuccessModal = ({ isOpen, close }) => {
  const { t } = useTranslation("kyb/kyb");
  return (
    <CenteredModalJack isOpen={isOpen} close={close} woCloseButton>
      <div className="d-flex flex-column align-items-center justify-content-center p-4">
        <JackIcons
          name="checkmark-circle-2"
          fill={jackColors.green06}
          className="hover"
          style={{ width: 120, height: 120 }}
          styleDiv={{
            padding: 9,
            borderRadius: 4,
          }}
        />
        <GothamMedium className="font20" style={{ color: jackColors.black34 }}>
          {t("We've received your response!")}
        </GothamMedium>
        <GothamRegular
          className="font14"
          style={{ color: jackColors.black34, marginTop: 8 }}
        >
          {t(
            "Thank you for your participation. We appreciate your time and effort."
          )}
        </GothamRegular>
        <ButtonJack
          onClick={close}
          style={{ marginTop: 40, width: 200 }}
          className="w-100"
        >
          {t("Close")}
        </ButtonJack>
      </div>
    </CenteredModalJack>
  );
};
